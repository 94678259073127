/* eslint-disable react/jsx-props-no-spreading */

import React from "react";
import cn from "classnames";
import {
  HeightDetector,
  NoJs,
  Theme,
  Header,
  PageNavButtons,
  Notification,
  ToolkitFooter
} from "~components";
import * as styles from "./styles.module.scss";
import "~node_modules/modern-normalize/modern-normalize.css";
import "~styles/global.css";

const Layout = ({ children, className }) => (
  <>
    <NoJs />
    <HeightDetector />
    <Theme />
    <div className={cn(styles.container, className)}>{children}</div>
    <Header />
    <PageNavButtons />
    <Notification />
    <ToolkitFooter />
  </>
);

export default Layout;
