import React from "react";
import cn from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import { SliceConfig, IntersectionAnimation } from "~components";
import * as styles from "./styles.module.scss";

const Hero = ({ data: { backgroundImage, text, textColor } }) => {
  const NUMBER_OF_OVERLAY_SEGMENTS = 10;
  const segmentArray = [...Array(NUMBER_OF_OVERLAY_SEGMENTS)];

  return (
    <section className={styles.container}>
      <div className={styles.background}>
        {backgroundImage && (
          <GatsbyImage
            loading="eager"
            image={backgroundImage.asset.gatsbyImageData}
            alt=""
            style={{
              width: `100%`,
              height: `100%`,
              transform: `scale(1.01)` // Fixes weird glitch at edge of SVG background
            }}
          />
        )}
        <div className={styles.inverseOverlayContainer}>
          {segmentArray.map((_, i) => (
            <div
              style={{ animationDelay: `${0.4 + 0.1 * i}s` }}
              key={i}
              className={styles.inverseOverlaySegment}
            />
          ))}
        </div>
      </div>

      <div className={styles.content}>
        <SliceConfig config={{ textColor: { hex: `#ffffff` } }}>
          <IntersectionAnimation>
            <h1
              style={{ color: textColor?.hex }}
              className={cn(`d1`, styles.header)}
            >
              {text}
            </h1>
          </IntersectionAnimation>
        </SliceConfig>
      </div>
    </section>
  );
};

export default Hero;
