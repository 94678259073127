import React from "react";
import cn from "classnames";
import { camelCaseToTitleCase } from "~utils";
import * as styles from "./styles.module.scss";
import { Button } from "~components";

interface IProps {
  config: any;
  updateConfig: (field: string, value: any) => void;
  field: string;
}

const SchemaToggle = ({ config, updateConfig, field }: IProps) => (
  <>
    <div className={cn(`caption`, styles.schemaLabel)}>
      {camelCaseToTitleCase(field)}
    </div>

    <div className={styles.buttons}>
      <Button
        variant={!config[field] ? `primary` : `secondary`}
        onClick={() => updateConfig(field, false)}
      >
        Off
      </Button>

      <Button
        variant={config[field] ? `primary` : `secondary`}
        onClick={() => updateConfig(field, true)}
      >
        On
      </Button>
    </div>
  </>
);

export default SchemaToggle;
