import React from "react";
import { IntersectionAnimation } from "~components";
import cn from "classnames";
import { useInView } from "react-intersection-observer";
import * as styles from "./styles.module.scss";
import LinkTile, { ILinkTile } from "./components/LinkTile";
import SlicePadding from "./components/SlicePadding";

export interface ILinkTiles {
  header: string;
  linkTiles?: ILinkTile[];
  backgroundColor: string;
  textColor: string;
}

interface IProps {
  data: ILinkTiles;
}

export type TFilterCategory = null | ICategoryWithTally;

export interface ICategoryWithTally {
  name: string;
  tally: number;
}

const LinkTiles = ({
  data: { header, linkTiles, backgroundColor, textColor }
}: IProps) => {
  const { ref, inView } = useInView({
    rootMargin: `-90px`,
    triggerOnce: true
  });

  return (
    <div style={{ background: backgroundColor, color: textColor }}>
      {header && (
        <SlicePadding>
          <h1 className={cn("h2")}>{header}</h1>
        </SlicePadding>
      )}

      <div ref={ref}>
        <SlicePadding config={{ paddingTop: header ? `none` : `regular` }}>
          <div className={styles.linkTiles}>
            {linkTiles?.map(
              ({ backgroundColor, category, image, linkUrl, title }, i) => {
                return (
                  <IntersectionAnimation
                    key={i}
                    delay={150 * i}
                    animation="fadeGrow"
                    trigger={inView}
                    className={styles.linkTile}
                  >
                    <LinkTile
                      category={category}
                      image={image}
                      linkUrl={linkUrl}
                      title={title}
                      backgroundColor={backgroundColor}
                    />
                  </IntersectionAnimation>
                );
              }
            )}
          </div>
        </SlicePadding>
      </div>
    </div>
  );
};

export default LinkTiles;
