import React, { useState } from "react";
import * as styles from "./styles.module.scss";
import cn from "classnames";
import features, { IFeature } from "./data";
import { Button } from "~components";

interface IProps {}

const FeatureBrowser = (props: IProps) => {
  const [activeFeature, setActiveFeature] = useState<IFeature>();
  const [hasPurchased, setHasPurchased] = useState(false);

  const handleBuy = () => {
    setHasPurchased(true);
  };

  const dependentFeatures = activeFeature?.dependentFeatureSlugs?.map((slug) =>
    features.find((feature) => feature.slug === slug)
  );

  const getTotalPrice = () => {
    if (!dependentFeatures) return activeFeature?.price;

    const activeFeaturePrice = parseFloat(
      activeFeature?.price.replace("$", "") || ""
    );

    const dependentFeaturesPriceArray: number[] = dependentFeatures.map(
      (feature) => parseFloat(feature?.price.replace("$", "") || "")
    );

    const totalDependentFeaturesPrice = dependentFeaturesPriceArray.reduce(
      (a, b) => a + b,
      0
    );

    const total = activeFeaturePrice + totalDependentFeaturesPrice;
    const totalDecimals = total.toFixed(2);

    return `$${totalDecimals}`;
  };

  return (
    <div className={styles.container}>
      <div className={styles.features}>
        {features.map((feature) => (
          <button
            onClick={() => setActiveFeature(feature)}
            className={cn("b1", styles.featureButton, {
              [styles.active]: activeFeature?.slug === feature.slug
            })}
          >
            {feature.name}
          </button>
        ))}
      </div>

      <div className={styles.featureDetails}>
        {activeFeature ? (
          <>
            <h3 className={cn("h2", styles.title)}>{activeFeature.name}</h3>
            <p className={cn("b1", styles.description)}>
              {activeFeature.description}
            </p>

            {dependentFeatures?.[0] && (
              <div className={styles.dependencies}>
                <p className={cn("b1")}>
                  Requires
                  <ul>
                    {dependentFeatures.map((feature, i) => (
                      <li className={styles.listItem} key={i}>
                        {feature?.name} (+ {feature?.price})
                      </li>
                    ))}
                  </ul>
                </p>
              </div>
            )}

            <p className={cn("b2")}>Price:</p>
            <p className={cn("h3", styles.price)}>{getTotalPrice()}</p>

            <Button onClick={handleBuy}>
              {hasPurchased ? "Added!" : "Add To Cart"}
            </Button>
          </>
        ) : (
          <h3 className={cn("h2", styles.selectFeatureText)}>
            Select a feature...
          </h3>
        )}
      </div>
    </div>
  );
};

export default FeatureBrowser;
