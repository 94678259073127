import React from "react";
import * as styles from "./styles.module.scss";
import cn from "classnames";
import { AutoHeightTextArea, Button } from "~components";

interface IProps {
  content: string | undefined;
  handleUpdateActivePostContent: (updatedContent: string) => void;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditingUI = ({
  content,
  handleUpdateActivePostContent,
  setIsEditing
}: IProps) => {
  return (
    <div className={styles.container}>
      <label className={styles.label}>
        <span className={cn("b2", styles.labelText)}>Caption:</span>
        <AutoHeightTextArea
          content={content}
          handleUpdateActivePostContent={handleUpdateActivePostContent}
        />
      </label>

      <Button
        disabled={!content}
        onClick={() => setIsEditing(false)}
        variant="tertiary"
        iconRight="check"
      >
        Save
      </Button>
    </div>
  );
};

export default EditingUI;
