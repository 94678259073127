import React from "react";
import { usePlayground } from "~hooks";
import { IComponentSchemaField } from "src/hooks/usePlayground";
import { ILinkTiles, LinkTiles } from "~slices";

export const colorOptions = [
  "#ffffff",
  "#77007c",
  "#007f4f",
  "#ff8731",
  "#00394f",
  "#c6b792",
  "#ffde31"
];

const PgLinkTiles = () => {
  const schemaFields: IComponentSchemaField[] = [
    {
      title: "headerText",
      type: "textInput"
    },
    {
      title: "backgroundColor",
      type: "colorPicker",
      options: colorOptions
    },
    {
      title: "linkTiles",
      type: "linkTiles"
    }
  ];

  const { config, renderPlayground } = usePlayground(schemaFields);

  const getTextColor: () => string = () => {
    switch (config.backgroundColor) {
      case "#77007c":
      case "#007f4f":
      case "#00394f":
        return "white";
      case "#ffffff":
      case "#ff8731":
      case "#c6b792":
      case "#ffde31":
        return "black";
      default:
        return "black";
    }
  };

  const data: ILinkTiles = {
    header: config.headerText,
    backgroundColor: config.backgroundColor,
    textColor: getTextColor(),
    linkTiles: config.linkTiles
  };

  const previewComponent = <LinkTiles data={data} componentName="LinkTiles" />;

  return <>{renderPlayground(previewComponent, `slices`)}</>;
};

export default PgLinkTiles;
