import React, { useEffect, useState } from "react";
import cn from "classnames";
import { camelCaseToTitleCase } from "~utils";
import * as styles from "./styles.module.scss";
import { ILinkTile } from "src/components/Playground/pgSlices/PgLinkTiles/LinkTiles/components/LinkTile";
import { Button, ColorPicker, TextInput } from "~components";
interface IProps {
  config: any;
  updateConfig: (field: string, value: any) => void;
  field: string;
}

export const colorOptions = [
  "#77007c",
  "#007f4f",
  "#ff8731",
  "#00394f",
  "#c6b792",
  "#ffde31"
];

const SchemaLinkTiles = ({ config, updateConfig, field }: IProps) => {
  const [linkTiles, setLinkTiles] = useState<ILinkTile[]>([]);

  useEffect(() => {
    updateConfig(field, linkTiles);
  }, [linkTiles]);

  const addNewLinkTile = () => {
    const newLinkTile: ILinkTile = {
      backgroundColor: colorOptions[0],
      title: "TITLE",
      category: "CATEGORY",
      image: "https://images.unsplash.com/photo-1706452674137-5cf451fc58b3",
      linkUrl: ""
    };

    setLinkTiles((prev) => [...prev, newLinkTile]);
  };

  const deleteLinkTile = (index: number) => {
    setLinkTiles((prev) => {
      const newLinkTiles = [...prev];
      newLinkTiles.splice(index, 1);
      return newLinkTiles;
    });
  };

  const updateTileValue = (
    index: number,
    field: keyof ILinkTile,
    value: any
  ) => {
    setLinkTiles((prev) => {
      const newLinkTiles = [...prev];
      newLinkTiles[index][field] = value;
      return newLinkTiles;
    });
  };

  return (
    <>
      <div className={cn(`caption`, styles.schemaLabel)}>
        {camelCaseToTitleCase(field)}
      </div>

      <div className={styles.tiles}>
        {linkTiles?.map((linkTile, i) => (
          <div key={i} className={styles.tile}>
            <div className={styles.form}>
              <TextInput
                onChange={(value) => updateTileValue(i, "category", value)}
                label="Category"
                value={linkTile.category}
              />

              <TextInput
                onChange={(value) => updateTileValue(i, "title", value)}
                label="Title"
                value={linkTile.title}
              />

              <ColorPicker
                label="Theme Color"
                colors={colorOptions}
                selectedColor={config.linkTiles[i]?.backgroundColor}
                updateSelectedColor={(color) =>
                  updateTileValue(i, "backgroundColor", color)
                }
              />
            </div>

            <div>
              <button
                className={styles.button}
                onClick={() => deleteLinkTile(i)}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>

      {linkTiles.length < 3 && (
        <Button onClick={addNewLinkTile}>Add New Tile</Button>
      )}
    </>
  );
};

export default SchemaLinkTiles;
