// extracted by mini-css-extract-plugin
export var backButton = "styles-module--backButton--QypfJ";
export var campaignSection = "styles-module--campaignSection--SjRBu";
export var container = "styles-module--container--sW+ie";
export var description = "styles-module--description--yWxPw";
export var desktop = "1340px";
export var giant = "2200px";
export var introText = "styles-module--introText--PMPmJ";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var magicPenContainer = "styles-module--magicPenContainer--fXFZb";
export var magicPenContent = "styles-module--magicPenContent--HzSZH";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var textArea = "styles-module--textArea--iKRlC";