export const useSanitySlices = (slices) => {
  const getSliceByName = (type) =>
    slices.find((slice) => type?.toLowerCase() === slice?._type?.toLowerCase());

  const getSlicesByName = (type) =>
    slices.filter(
      (slice) => type?.toLowerCase() === slice?._type?.toLowerCase()
    );

  // ---------------------------------------------------------------------------

  return {
    getSliceByName,
    getSlicesByName,
    slices
  };
};

export default useSanitySlices;
