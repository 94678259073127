import React from "react";
import { Helmet } from "react-helmet";
import { useSiteMetadata, useDarkMode } from "~hooks";

const SEO = ({ pageTitle }) => {
  const meta = useSiteMetadata();
  const siteTitle = meta.title;
  const titleText =
    pageTitle === `Home` ? siteTitle : `${pageTitle} | ${siteTitle}`;

  const isDarkMode = useDarkMode();
  const favicon = isDarkMode
    ? `/images/favicon-darkmode.png`
    : `/images/favicon-default.png`;

  return (
    <>
      <Helmet>
        <title>{titleText}</title>
        <link rel="icon" type="image/png" href={favicon} />
      </Helmet>
    </>
  );
};

export default SEO;
