import React from "react";
import * as styles from "./styles.module.scss";
import { SVG } from "~components";

interface IProps {
  setShowMediaLibrary: (value: React.SetStateAction<boolean>) => void;
}

const AddMediaButton = ({ setShowMediaLibrary }: IProps) => {
  return (
    <button
      onClick={() => setShowMediaLibrary(true)}
      className={styles.addImageButton}
    >
      <div className={styles.addButtonContent}>
        <SVG svg="plus" className={styles.plusIcon} />
      </div>
    </button>
  );
};

export default AddMediaButton;
