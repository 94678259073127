/* eslint-disable react/jsx-props-no-spreading */

import React from "react";

import { ReactComponent as arrowDown } from "~assets/svg/arrow-down.svg";
import { ReactComponent as arrowLeft } from "~assets/svg/arrow-left.svg";
import { ReactComponent as arrowRight } from "~assets/svg/arrow-right.svg";
import { ReactComponent as arrowUpRight } from "~assets/svg/arrow-up-right.svg";
import { ReactComponent as lookingGlass } from "~assets/svg/looking-glass.svg";
import { ReactComponent as minus } from "~assets/svg/minus.svg";
import { ReactComponent as playCircle } from "~assets/svg/play-circle.svg";
import { ReactComponent as plus } from "~assets/svg/plus.svg";
import { ReactComponent as warningTriangle } from "~assets/svg/warning-triangle.svg";
import { ReactComponent as x } from "~assets/svg/x.svg";
import { ReactComponent as check } from "~assets/svg/check.svg";
import { ReactComponent as download } from "~assets/svg/download.svg";
import { ReactComponent as copy } from "~assets/svg/copy.svg";
import { ReactComponent as refresh } from "~assets/svg/refresh.svg";
import { ReactComponent as wordmark } from "~assets/svg/wordmark.svg";
import { ReactComponent as edit } from "~assets/svg/edit.svg";
import { ReactComponent as file } from "~assets/svg/file.svg";
import { ReactComponent as pen } from "~assets/svg/pen.svg";
import { ReactComponent as lock } from "~assets/svg/lock.svg";
import { ReactComponent as unlock } from "~assets/svg/unlock.svg";

const svgs = Object.freeze({
  arrowDown,
  arrowLeft,
  arrowRight,
  arrowUpRight,
  download,
  copy,
  lookingGlass,
  minus,
  lock,
  unlock,
  playCircle,
  plus,
  warningTriangle,
  x,
  check,
  pen,
  refresh,
  wordmark,
  edit,
  file
});

export type TSvg = keyof typeof svgs;

interface IProps {
  svg: TSvg;
  className?: string;
  style?: React.CSSProperties;
}

const SVG = ({ svg, ...props }: IProps) => {
  /** ============================================================================
   * @component
   * Used to render SVG icons
   *
   * @param {string}     svg     Name of desired SVG, must be imported first
   *
   * @return {node}
   */

  if (!svgs[svg]) return null;

  const SVGElement = svgs[svg];

  return <SVGElement {...props} aria-hidden />;
};

export default SVG;
