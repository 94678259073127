import React from "react";
import cn from "classnames";
import { Link } from "gatsby";
import DisabledFeatureLink from "./components/DisabledFeatureLink";
import * as styles from "./styles.module.scss";

interface IProps {
  disabled: boolean;
  isActivePage: boolean;
  title: string;
  slug: {
    current: string;
  };
}

const PageLink = ({ disabled, isActivePage, title, slug }: IProps) => (
  <div className="h3">
    {disabled ? (
      <DisabledFeatureLink title={title} />
    ) : (
      <Link
        className={cn(styles.listItem, {
          [styles.activePage]: isActivePage
        })}
        to={`/${slug.current}`}
      >
        {title}
      </Link>
    )}
  </div>
);

export default PageLink;
