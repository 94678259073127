import React, { useRef } from "react";
import cn from "classnames";
import { Link } from "gatsby";
import { SVG } from "~components";
import * as styles from "./styles.module.scss";

const PageNavButton = ({ className, to, text, direction }) => {
  const buttonRef = useRef(null);

  return (
    <Link ref={buttonRef} className={cn(className, styles.button)} to={to}>
      <div className={styles.content}>
        {direction === `prev` && (
          <SVG svg="arrowLeft" className={styles.icon} />
        )}
        <span className={styles.text}>{text}</span>
        {direction === `next` && (
          <SVG svg="arrowRight" className={styles.icon} />
        )}
      </div>
    </Link>
  );
};

export default PageNavButton;
