/* eslint-disable camelcase */
import React, { createContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { globalHistory } from "@reach/router";
import * as styles from "~styles/disableScroll.module.scss";

export interface IAppContext {
  isHeaderOpen: boolean;
  setIsHeaderOpen: React.Dispatch<React.SetStateAction<boolean>>;
  pathname: string;
  notificationMessage: string;
  updateNotificationMessage: (newMessage: string) => void;
  isNotificationVisible: boolean;
}

export const AppContext = createContext<IAppContext>({} as IAppContext);

interface IProps {
  children: React.ReactNode;
}
const AppProvider = ({ children }: IProps) => {
  const [isHeaderOpen, setIsHeaderOpen] = useState(false);
  const [pathname, setPathname] = useState("");
  const [notificationMessage, setNotificationMessage] = useState(``);
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);

  useEffect(() => {
    if (typeof window !== `undefined` && window?.location?.pathname) {
      setPathname(window.location.pathname);
    }

    return globalHistory.listen(({ location }) => {
      setPathname(location.pathname);
    });
  }, []);

  // Close menu on page change
  useEffect(() => {
    if (isHeaderOpen) {
      setIsHeaderOpen(false);
    }
  }, [pathname]);

  // Disable page scroll
  useEffect(() => {
    if (isHeaderOpen) {
      document.body.classList.add(styles.disableScroll);
    } else {
      document.body.classList.remove(styles.disableScroll);
    }
  }, [isHeaderOpen]);

  // Notification
  const notificationTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const NOTIFICATION_LENGTH_MS = 1500;

  const updateNotificationMessage = (newMessage: string) => {
    if (notificationTimeoutRef.current) {
      clearTimeout(notificationTimeoutRef.current);
    }
    setNotificationMessage(newMessage);
    setIsNotificationVisible(true);
    notificationTimeoutRef.current = setTimeout(() => {
      setIsNotificationVisible(false);
    }, NOTIFICATION_LENGTH_MS);
  };

  return (
    <AppContext.Provider
      value={{
        isHeaderOpen,
        setIsHeaderOpen,
        pathname,
        notificationMessage,
        isNotificationVisible,
        updateNotificationMessage
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppProvider;
