import Accordion from "./Common/Accordion";
import Button from "./Common/Button";
import HTMLVideo from "./Common/HTMLVideo";
import Image from "./Common/Image";
import IntersectionAnimation from "./Common/IntersectionAnimation";
import Link from "./Common/Link";
import LinkTag from "./Common/LinkTag";
import NoJs from "./Common/NoJs";
import ColorPicker from "./Common/ColorPicker";
import PasswordOverlay from "./Common/PasswordOverlay";
import Quantity from "./Common/Quantity";
import SEO from "./Common/SEO";
import SliceConfig from "./Common/SliceConfig";
import SVG from "./Common/SVG";
import { TSvg } from "./Common/SVG";
import TextInput from "./Common/TextInput";
import Colors from "./Common/Theme/Colors";
import Fonts from "./Common/Theme/Fonts";
import Grid from "./Common/Theme/Grid";
import Theme from "./Common/Theme";
import HeightDetector from "./Common/Theme/HeightDetector";
import Background from "./DBP/Background";

import ASGColors from "./DBP/ASG/ASGColors";
import ASGTypography from "./DBP/ASG/ASGTypography";
import ColorGridTile from "./DBP/ColorGridTile";
import DesignPrincipleBlock from "./DBP/DesignPrincipleBlock";
import Header from "./DBP/Header";
import Notification from "./DBP/Notification";
import PageLink from "./DBP/PageLink";
import PageNavButtons from "./DBP/PageNavButtons";
import WebComponents from "./DBP/WebComponents";

import PortableTextRenderer from "./Sanity/PortableTextRenderer";
import SanitySlice from "./Sanity/SanitySlice";

import AutoHeightTextArea from "./Common/AutoHeightTextArea";
import ToolkitFooter from "./Common/ToolkitFooter";
import FeatureBrowser from "./DBP/FeatureBrowser";

/**************************************
 * Playground
 * */
import PlaygroundWrapper from "./Playground/PlaygroundWrapper";
import type { TInspectorDirectory } from "./Playground/PlaygroundWrapper";

// Components
import PgButton from "./Playground/pgComponents/PgButton";
import PgTextInput from "./Playground/pgComponents/PgTextInput";

const pgComponents = Object.freeze({
  button: PgButton,
  textInput: PgTextInput
});

type TPGComponent = keyof typeof pgComponents;

// Slices
import PgHero from "./Playground/pgSlices/PgHero";
import PgLinkTiles from "./Playground/pgSlices/PgLinkTiles";

const pgSlices = Object.freeze({
  // hero: PgHero,
  linkTiles: PgLinkTiles
});

type TPGSlice = keyof typeof pgSlices;

export {
  TPGSlice,
  TPGComponent,
  PlaygroundWrapper,
  ColorPicker,
  TInspectorDirectory,
  pgSlices,
  AutoHeightTextArea,
  Accordion,
  Button,
  FeatureBrowser,
  Grid,
  HTMLVideo,
  Image,
  IntersectionAnimation,
  Link,
  LinkTag,
  Theme,
  Background,
  NoJs,
  PasswordOverlay,
  Quantity,
  SEO,
  SliceConfig,
  SVG,
  TSvg,
  TextInput,
  Colors,
  Fonts,
  HeightDetector,
  ASGColors,
  ASGTypography,
  ColorGridTile,
  DesignPrincipleBlock,
  Header,
  Notification,
  PageLink,
  PageNavButtons,
  WebComponents,
  PortableTextRenderer,
  SanitySlice,
  ToolkitFooter
};
