export const camelCaseToTitleCase = (camelCase: string) => {
  const splitToWords = camelCase.replace(/([A-Z])/g, ` $1`);
  const capitalise =
    splitToWords.charAt(0).toUpperCase() + splitToWords.slice(1);
  return capitalise;
};

export const doesRawTextHaveContent = (...rawTextItems: any[]) => {
  for (let i = 0; i < rawTextItems.length; i++) {
    if (rawTextItems[i]?.[0]?.children[0]?.text) {
      return true;
    }
  }
  return false;
};
