import { useContext } from "react";
import { AppContext, IAppContext } from "~context";

export const useApp = (): IAppContext => {
  const app = useContext(AppContext);

  return app;
};

export default useApp;
