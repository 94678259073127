// extracted by mini-css-extract-plugin
export var container = "styles-module--container--RO7iR";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--ZRFWe";
export var gridItem = "styles-module--gridItem--r2Z1w";
export var gridItemBackground = "styles-module--gridItemBackground--XJ7rc";
export var gridItemContent = "styles-module--gridItemContent--qfAAU";
export var gridItemDisabled = "styles-module--gridItemDisabled--oRUok";
export var gridItemL = "styles-module--gridItemL--Ko-60";
export var gridItemM = "styles-module--gridItemM--ILCj4";
export var gridItemS = "styles-module--gridItemS--TyckX";
export var gridItemSubtitle = "styles-module--gridItemSubtitle--h58IW";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";