// extracted by mini-css-extract-plugin
export var buttons = "styles-module--buttons--pnmzG";
export var container = "styles-module--container--GPIVm";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var leftColumn = "styles-module--leftColumn--UJDav";
export var linkTags = "styles-module--linkTags--A3tag";
export var mobile = "400px";
export var rightColumn = "styles-module--rightColumn--Svy2u";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";