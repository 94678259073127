// Not updated to V2
// Removed from pagebuilder

import React from "react";
import { Button, Link, PortableTextRenderer } from "~components";
import { ReactComponent as ArrowIcon } from "~assets/svg/arrow.svg";
import { ReactComponent as DownloadIcon } from "~assets/svg/download.svg";
import { ReactComponent as MailIcon } from "~assets/svg/mail.svg";
import * as styles from "./styles.module.scss";

const TextArticle = ({
  id,
  className,
  data: {
    backgroundColor,
    fontColor,
    heading,
    headingStyle,
    subheading,
    status,
    padded,
    dockedTop,
    flattenTop,
    dockedBottom,
    flattenBottom,
    buttonIcon,
    buttonType,
    buttonAlignment,
    button,
    _rawBody
  }
}) => {
  // ---------------------------------------------------------------------------
  // variables

  let buttonIconJSX = null;
  let buttonJSX = null;

  // ---------------------------------------------------------------------------
  // render

  switch (buttonIcon?.toLowerCase()) {
    case `arrow`:
      buttonIconJSX = <ArrowIcon className={styles.arrowIcon} fill="white" />;

      break;

    case `download`:
      buttonIconJSX = (
        <DownloadIcon fill="white" className={styles.downloadIcon} />
      );

      break;

    case `mail`:
      buttonIconJSX = <MailIcon className={styles.mailIcon} fill="white" />;

      break;

    case `none`:
    default:
      break;
  }

  if (button?.text !== `` && button?.url) {
    switch (buttonType?.toLowerCase()) {
      case `bordered`:
        buttonJSX = (
          <Button color="white" to={button.url} transparent>
            <span className="button-text">{button.text}</span>

            {buttonIconJSX}
          </Button>
        );

        break;

      case `inline`:
      default:
        buttonJSX = (
          <Link to={button.url}>
            <span className="button-text">{button.text}</span>

            {buttonIconJSX}
          </Link>
        );

        break;
    }
  }

  return (
    <section
      sliceid={id}
      className={[
        className,
        styles.container,
        dockedTop ? styles.dockedTop : ``,
        flattenTop ? styles.flattenTop : ``,
        dockedBottom ? styles.dockedBottom : ``,
        flattenBottom ? styles.flattenBottom : ``,
        padded ? styles.padded : ``
      ].join(` `)}
      style={{
        background: backgroundColor?.hex || `transparent`,
        color: fontColor?.hex || `white`
      }}
    >
      <div className={styles.grid}>
        <header className={styles.header}>
          <h2
            className={`${styles.headerHeading} ${headingStyle?.style || `b1`}`}
          >
            {heading}
          </h2>

          {subheading && <h3 className="b1">{subheading}</h3>}

          {status && <h3 className={`${styles.headerStatus} b2`}>{status}</h3>}
        </header>

        <div
          className={[
            styles.content,
            buttonAlignment === `Right` ? styles.contentFlex : ``
          ].join(` `)}
        >
          {_rawBody && (
            <div className={[styles.rawBody]}>
              <PortableTextRenderer rawText={_rawBody} />
            </div>
          )}

          {buttonJSX && (
            <div
              className={[
                styles.buttonContainer,
                buttonAlignment === `Right`
                  ? styles.buttonContainerRightAligned
                  : ``
              ].join(` `)}
            >
              {buttonJSX}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default TextArticle;
