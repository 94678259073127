import React, { useRef } from "react";
import * as styles from "./styles.module.scss";
import PlaygroundSection from "../PlaygroundSection";
import { useReactHtml, useApp } from "~hooks";

interface IProps {
  previewComponent: JSX.Element;
}

const HTMLInspector = ({ previewComponent }: IProps) => {
  const domRef = useRef<HTMLDivElement>(null);
  const html = useReactHtml(domRef);

  const { updateNotificationMessage } = useApp();

  const copyHTMLToClipboard = () => {
    updateNotificationMessage(`Copied to clipboard!`);
    navigator.clipboard.writeText(html);
  };

  return (
    <>
      <div className={styles.hidden} ref={domRef}>
        {previewComponent}
      </div>

      <PlaygroundSection
        title="HTML"
        button={{
          icon: "copy",
          labelText: `Copy HTML to clipboard`,
          onClick: copyHTMLToClipboard
        }}
      >
        <pre className={styles.code}>{html}</pre>
      </PlaygroundSection>
    </>
  );
};

export default HTMLInspector;
