import React, { useEffect, useRef, useState } from "react";
import * as styles from "./styles.module.scss";
import { Button } from "~components";
import useMagicPenContext from "../../context";

const PromptTemplateInput = () => {
  const { selectedPromptTemplate, setConstructedPrompt } = useMagicPenContext();

  const extractPlaceholdersAndStrings = () => {
    const placeholderRegex = /\[([^\]]+)\]/g;

    let placeholders = [];

    let match;
    while ((match = placeholderRegex.exec(selectedPromptTemplate)) !== null) {
      placeholders.push(match[1]);
    }

    let text = [];

    const splitRegex = /\[[^\]]+\]/;

    text = selectedPromptTemplate
      .split(splitRegex)
      .filter((string) => string.trim() !== "");

    return {
      text,
      placeholders
    };
  };
  const { placeholders, text } = extractPlaceholdersAndStrings();

  const getInitialMadlibValues = () => placeholders.map(() => "");

  const [madlibValues, setMadlibValues] = useState<string[]>(
    getInitialMadlibValues()
  );

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const firstInput = containerRef.current?.querySelector("input");
    firstInput?.focus();
  }, []);

  const maxNumberOfElements = Math.max(placeholders.length, text.length);
  const numberElementsArray = new Array(maxNumberOfElements).fill("");

  const isNextButtonDisabled = madlibValues.some((value) => value === "");

  const getInputWidth = (inputValue: string) => {
    if (!inputValue) return 0;

    const tmpSpan = document.createElement("span");
    tmpSpan.style.display = "inline-block";
    tmpSpan.style.visibility = "hidden";
    tmpSpan.textContent = inputValue.replace(/ /g, "\u00a0"); // Replace spaces with non-breaking spaces to ensure accurate width measurement

    document.body.appendChild(tmpSpan);
    const inputContentsWidth = tmpSpan.getBoundingClientRect().width;
    document.body.removeChild(tmpSpan);

    return inputContentsWidth + 16;
  };

  const confirmConstructedPrompt = () => {
    let constructedPrompt = "";
    for (let i = 0; i < maxNumberOfElements; i++) {
      constructedPrompt += text[i] || "";
      constructedPrompt += madlibValues[i] || "";
    }

    setConstructedPrompt(constructedPrompt);
  };

  useEffect(() => {
    const isTemplate = /\[([^\]]+)\]/.test(selectedPromptTemplate);

    if (!isTemplate) {
      setConstructedPrompt(selectedPromptTemplate);
    }
  }, []);

  return (
    <>
      <div className={styles.container} ref={containerRef}>
        {numberElementsArray.map((_, i) => {
          const currentText = text[i];
          const currentPlaceholder = placeholders[i];

          const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setMadlibValues((prev) =>
              prev.map((value, index) => {
                if (index === i) {
                  return e.target.value;
                }

                return value;
              })
            );
          };

          const inputWidth = getInputWidth(madlibValues[i]);

          return (
            <>
              {currentText && <p className={styles.text}>{currentText}</p>}
              {currentPlaceholder && (
                <input
                  style={{ width: inputWidth }}
                  placeholder={currentPlaceholder}
                  value={madlibValues[i]}
                  onChange={handleChange}
                  className={styles.input}
                />
              )}
            </>
          );
        })}
      </div>

      <Button
        onClick={confirmConstructedPrompt}
        disabled={isNextButtonDisabled}
      >
        Next
      </Button>
    </>
  );
};

export default PromptTemplateInput;
