// extracted by mini-css-extract-plugin
export var background = "styles-module--background--EAJbC";
export var container = "styles-module--container--0nKjO";
export var content = "styles-module--content--A2XFh";
export var desktop = "1340px";
export var giant = "2200px";
export var header = "styles-module--header---k1HZ";
export var inverseOverlayContainer = "styles-module--inverseOverlayContainer--0p7cU";
export var inverseOverlaySegment = "styles-module--inverseOverlaySegment--80AV4";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var swipeLeft = "styles-module--swipe-left--FsZDR";
export var tablet = "769px";