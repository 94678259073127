import React from "react";
import * as styles from "./styles.module.scss";
import cn from "classnames";

interface IProps {
  label?: string;
  colors: string[];
  selectedColor: string;
  updateSelectedColor: (color: string) => void;
}

const ColorPicker = ({
  colors,
  selectedColor,
  updateSelectedColor,
  label
}: IProps) => {
  return (
    <div>
      {label && <div className={cn(`caption`, styles.label)}>{label}</div>}
      <div className={styles.buttons}>
        {colors.map((color, i) => (
          <button
            className={cn(styles.button, {
              [styles.active]: color === selectedColor
            })}
            key={i}
            style={{ background: color }}
            onClick={() => updateSelectedColor(color)}
          />
        ))}
      </div>
    </div>
  );
};

export default ColorPicker;
