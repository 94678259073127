import React, { useState } from "react";
import { PlaygroundWrapper, TInspectorDirectory } from "~components";
import { camelCaseToTitleCase } from "~utils";

export interface IComponentSchemaField {
  title: string;
  type:
    | "textInput"
    | "toggle"
    | "options"
    | "sliceConfig"
    | "colorPicker"
    | "linkTiles";
  initialValue?: any;
  options?: string[];
}

const usePlayground = (componentSchema: IComponentSchemaField[]) => {
  const initialConfig: any = {};

  componentSchema?.forEach((field) => {
    switch (field.type) {
      case `toggle`:
        initialConfig[field.title] = false;
        break;
      case `textInput`:
        initialConfig[field.title] =
          field.initialValue || camelCaseToTitleCase(field.title);
        break;
      case `options`:
        initialConfig[field.title] = field.options?.[0];
        break;
      case `colorPicker`:
        initialConfig[field.title] = field.options?.[0];
        break;
      case `linkTiles`:
        initialConfig[field.title] = [];
      case `sliceConfig`:
        break;
      default:
        throw new Error(`Error in usePlayground: field type not configured.`);
    }
  });

  const [config, setConfig] = useState(initialConfig);

  const updateConfig = (field: string, value: any) => {
    setConfig((prev: any) => ({
      ...prev,
      [field]: value
    }));
  };

  const renderPlayground = (
    previewComponent: JSX.Element,
    directory: TInspectorDirectory
  ) => {
    return (
      <PlaygroundWrapper
        componentSchema={componentSchema}
        config={config}
        previewComponent={previewComponent}
        updateConfig={updateConfig}
        directory={directory}
      />
    );
  };

  return { config, renderPlayground };
};

export default usePlayground;
