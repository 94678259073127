import React from "react";
import typography from "~data/typography";
import { SliceConfig, IntersectionAnimation } from "~components";
import { useInView } from "react-intersection-observer";
import cn from "classnames";
import * as styles from "./styles.module.scss";

const TypeTable = ({ data: { subheadingStyle, sliceConfig } }) => (
  <section className={styles.container}>
    <SliceConfig config={sliceConfig}>
      {typography.map(({ min, max, styles: breakpointStyles }, i) => {
        const { ref, inView } = useInView({
          rootMargin: `-90px`
        });

        return (
          <div key={i} ref={ref} className={styles.breakpointSet}>
            <IntersectionAnimation>
              <div className={cn(styles.row, subheadingStyle?.style || `b2`)}>
                <div className={styles.wideColumn}>
                  {min}-{max === -1 ? `1920px+` : `${max}px`} Styles
                </div>
                <div className={styles.narrowColumn}>Font-size</div>
                <div className={styles.narrowColumn}>Line-height</div>
                <div className={styles.narrowColumn}>Letter-spacing</div>
              </div>

              <ul>
                {breakpointStyles.map(({ id: styleId, attributes }, j) => (
                  <IntersectionAnimation
                    key={j}
                    trigger={inView}
                    delay={j * 100}
                  >
                    <li className={styles.row}>
                      <div
                        className={styles.wideColumn}
                        style={{ ...attributes }}
                      >
                        {styleId}
                      </div>

                      <div className={cn(styles.narrowColumn, `b2`)}>
                        {attributes.fontSize}
                      </div>
                      <div className={cn(styles.narrowColumn, `b2`)}>
                        {attributes.lineHeight}
                      </div>
                      <div className={cn(styles.narrowColumn, `b2`)}>
                        {attributes.letterSpacing || `0em`}
                      </div>
                    </li>
                  </IntersectionAnimation>
                ))}
              </ul>
            </IntersectionAnimation>
          </div>
        );
      })}
    </SliceConfig>
  </section>
);

export default TypeTable;
