import React from "react";
import { Link } from "gatsby";
import cn from "classnames";
import * as styles from "./styles.module.scss";

const LinkTag = ({ linkType, hoverColor, linkInternal, linkExternal }) => {
  const customHoverStyle = {
    "--hover-bg-color": hoverColor.hex
  };

  return (
    <>
      {linkType === `internal` && (
        <Link
          to={`/${linkInternal.reference.slug.current}`}
          className={cn(`caption`, styles.linkTag)}
          style={customHoverStyle}
        >
          {linkInternal.title}
        </Link>
      )}
      {linkType === `external` && (
        <a
          href={linkExternal.url}
          target={linkExternal.newWindow ? `_blank` : `_self`}
          rel="noopener noreferrer"
          className={cn(`caption`, styles.linkTag)}
          style={customHoverStyle}
        >
          {linkExternal.title}
        </a>
      )}
    </>
  );
};

export default LinkTag;
