import React from "react";
import { camelCaseToTitleCase } from "~utils";
import * as styles from "./styles.module.scss";
import cn from "classnames";
import { TextInput } from "~components";

interface IProps {
  config: any;
  updateConfig: (field: string, value: any) => void;
  field: string;
}

const SchemaTextInput = ({ config, updateConfig, field }: IProps) => (
  <>
    <div className={cn(`caption`, styles.schemaLabel)}>
      {camelCaseToTitleCase(field)}
    </div>

    <TextInput
      onChange={(value) => updateConfig(field, value)}
      value={config[field]}
      placeholder="Enter text"
    />
  </>
);

export default SchemaTextInput;
