import React from "react";
import * as styles from "./styles.module.scss";
import cn from "classnames";
import { SVG } from "~components";

export interface IExternalUrl {
  url: string;
  _key: string;
  _type: `externalUrl`;
}

export interface IAltImage {
  altText: string;
  asset: {
    gatsbyImageData: any;
  };
}

export interface ILinkTile {
  className?: string;
  backgroundColor: string;
  linkUrl: string;
  title: string;
  category: string;
  image: string;
}

const LinkTile = ({
  backgroundColor,
  category,
  image,
  linkUrl,
  title,
  className
}: ILinkTile) => {
  return (
    <a
      className={cn(className, styles.linkTile)}
      href={linkUrl}
      target="_blank"
      rel="noreferrer noopener"
    >
      <LinkTileContent
        backgroundColor={backgroundColor}
        category={category}
        image={image}
        title={title}
      />
    </a>
  );
};

export default LinkTile;

interface ILinkTileContentProps {
  backgroundColor?: string;
  image: string;
  category: string;
  title: string;
}

const LinkTileContent = ({
  backgroundColor,
  image,
  category,
  title
}: ILinkTileContentProps) => {
  const backgroundStyle = {
    background: backgroundColor || `var(--color-black)`
  };

  const getTextColorForBackgroundBrandColor: (
    backgroundColor: string
  ) => string = (backgroundColor) => {
    switch (backgroundColor) {
      case "#77007c":
      case "#007f4f":
      case "#00394f":
        return "white";
      case "#ff8731":
      case "#c6b792":
      case "#ffde31":
        return "black";
      default:
        return "black";
    }
  };

  const textColor = getTextColorForBackgroundBrandColor(backgroundColor || "");

  return (
    <div
      className={styles.content}
      style={{
        background: backgroundColor || `var(--color-black)`,
        color: textColor || `var(--color-white)`
      }}
    >
      <div className={styles.text}>
        <div className={cn(styles.subheading, `b2`)}>{category}</div>
        <h3 className="h3">{title}</h3>
      </div>
      <div className={styles.imageContainer}>
        <img src={image} className={styles.image} />

        <div className={styles.iconContainer} style={backgroundStyle}>
          <SVG svg="arrowRight" className={styles.icon} />
        </div>
        <div
          className={cn(styles.blockGraphic, styles.topLeft)}
          style={backgroundStyle}
        />
        <div
          className={cn(styles.blockGraphic, styles.middleLeft)}
          style={backgroundStyle}
        />
        <div
          className={cn(styles.blockGraphic, styles.bottomLeft)}
          style={backgroundStyle}
        />
        <div
          className={cn(styles.blockGraphic, styles.bottomMiddle)}
          style={backgroundStyle}
        />
        <div
          className={cn(styles.blockGraphic, styles.bottomRight)}
          style={backgroundStyle}
        />
        <div
          className={cn(styles.blockGraphic, styles.middleRight)}
          style={backgroundStyle}
        />
      </div>
    </div>
  );
};
