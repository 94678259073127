import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";
import { templatePrompts } from "../../data";
import PromptCategory from "../../components/PromptCategory";

const TemplateSelect = () => {
  return (
    <>
      <h2 className={cn("h3", styles.title)}>Select a template</h2>

      <div className={styles.prompts}>
        {templatePrompts.map((category, i) => (
          <PromptCategory
            promptTemplates={category.promptTemplates}
            title={category.title}
            key={i}
          />
        ))}
      </div>
    </>
  );
};

export default TemplateSelect;
