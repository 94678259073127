// extracted by mini-css-extract-plugin
export var container = "styles-module--container--TFyo9";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var paddingBottomRegular = "styles-module--paddingBottomRegular--q2lsn";
export var paddingBottomSmall = "styles-module--paddingBottomSmall--gWFva";
export var paddingTopRegular = "styles-module--paddingTopRegular--QF5L5";
export var paddingTopSmall = "styles-module--paddingTopSmall---fsNN";
export var paddingXRegular = "styles-module--paddingXRegular--Dw3h-";
export var paddingXSmall = "styles-module--paddingXSmall--JLNUX";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";