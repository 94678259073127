import React from "react";
import { Image, SliceConfig, IntersectionAnimation } from "~components";
import * as styles from "./styles.module.scss";

const ImageGrid = ({ data: { images, sliceConfig } }) => {
  if (!images?.[0]) {
    return null;
  }

  return (
    <section>
      <IntersectionAnimation>
        <SliceConfig config={sliceConfig}>
          <div className={styles.grid}>
            {images.map((image, i) => (
              <IntersectionAnimation
                animation="fadeGrow"
                delay={i * 100}
                key={i}
                className={styles.image}
              >
                <Image image={image} />
              </IntersectionAnimation>
            ))}
          </div>
        </SliceConfig>
      </IntersectionAnimation>
    </section>
  );
};

export default ImageGrid;
