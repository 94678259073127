import React from "react";
import COLORS from "~data/colors.json";
import { SliceConfig, IntersectionAnimation, ColorGridTile } from "~components";
import { useInView } from "react-intersection-observer";
import * as styles from "./styles.module.scss";

const ColorGrid = ({ data: { category, sliceConfig } }) => {
  const { ref, inView } = useInView({
    rootMargin: `-90px`
  });

  return (
    <section>
      <SliceConfig config={sliceConfig}>
        <IntersectionAnimation>
          <ul className={styles.grid} ref={ref}>
            {Object.keys(COLORS).map((colorKey, i) => {
              const color = COLORS[colorKey];
              return (
                <ColorGridTile
                  index={i}
                  color={color}
                  inView={inView}
                  category={category}
                />
              );
            })}
          </ul>
        </IntersectionAnimation>
      </SliceConfig>
    </section>
  );
};

export default ColorGrid;
