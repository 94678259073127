// extracted by mini-css-extract-plugin
export var arrowIcon = "styles-module--arrowIcon--jY+n-";
export var buttonContainer = "styles-module--buttonContainer--VhB6R";
export var buttonContainerRightAligned = "styles-module--buttonContainerRightAligned--U+GV6";
export var container = "styles-module--container--zOfvO";
export var content = "styles-module--content--8T0pM";
export var contentFlex = "styles-module--contentFlex--RfHzQ";
export var desktop = "1340px";
export var dockedBottom = "styles-module--dockedBottom--QLL8A";
export var dockedTop = "styles-module--dockedTop--0ec9g";
export var downloadIcon = "styles-module--downloadIcon--Cu-NH";
export var flattenBottom = "styles-module--flattenBottom--f89nY";
export var flattenTop = "styles-module--flattenTop--V6P9S";
export var giant = "2200px";
export var grid = "styles-module--grid--Li0l2";
export var header = "styles-module--header--LTIYb";
export var headerHeading = "styles-module--headerHeading--9CG35";
export var headerStatus = "styles-module--headerStatus--sml-W";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mailIcon = "styles-module--mailIcon--h5ws9";
export var mobile = "400px";
export var padded = "styles-module--padded--cF63m";
export var rawBody = "styles-module--rawBody--sTIao";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";