import React from "react";
import * as styles from "./styles.module.scss";
import { IComponentSchemaField } from "~hooks";
import SchemaToggle from "./components/SchemaToggle";
import SchemaOptions from "./components/SchemaOptions";
import SchemaTextInput from "./components/SchemaTextInput";
import SchemaSliceConfig from "./components/SchemaSliceConfig";
import PlaygroundSection from "../PlaygroundSection";
import SchemaColorPicker from "./components/SchemaColorPicker";
import SchemaLinkTiles from "./components/SchemaLinkTiles";

interface IProps {
  componentSchema: IComponentSchemaField[];
  config: any;
  updateConfig: (field: string, value: any) => void;
}

const Configuration = ({ componentSchema, config, updateConfig }: IProps) => {
  const getField = (field: IComponentSchemaField) => {
    switch (field.type) {
      case `textInput`:
        return (
          <SchemaTextInput
            key={field.title}
            config={config}
            updateConfig={updateConfig}
            field={field.title}
          />
        );
      case `toggle`:
        return (
          <SchemaToggle
            key={field.title}
            config={config}
            updateConfig={updateConfig}
            field={field.title}
          />
        );
      case `colorPicker`:
        return (
          <SchemaColorPicker
            key={field.title}
            config={config}
            updateConfig={updateConfig}
            field={field.title}
            options={field.options}
          />
        );
      case `options`:
        return (
          <SchemaOptions
            key={field.title}
            config={config}
            updateConfig={updateConfig}
            field={field.title}
            options={field.options as string[]}
          />
        );
      case `sliceConfig`:
        return (
          <SchemaSliceConfig
            key={field.title}
            updateConfig={updateConfig}
            field={field.title}
          />
        );
      case `linkTiles`:
        return (
          <SchemaLinkTiles
            key={field.title}
            field={field.title}
            config={config}
            updateConfig={updateConfig}
          />
        );
      default:
        return <p>Invalid field type</p>;
    }
  };

  return (
    <PlaygroundSection title="Configuration">
      {componentSchema.map((field, i) => (
        <div key={i} className={styles.field}>
          {getField(field)}
        </div>
      ))}
    </PlaygroundSection>
  );
};

export default Configuration;
