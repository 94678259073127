import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";

type IPaddingOption = `regular` | `small` | `none`;

export interface ISlicePadding {
  paddingTop?: IPaddingOption;
  paddingBottom?: IPaddingOption;
  paddingX?: IPaddingOption;
}

interface IProps {
  children: React.ReactNode;
  config?: ISlicePadding;
  xOnly?: boolean;
  yOnly?: boolean;
  className?: string;
}

const SlicePadding = ({
  children,
  className,
  config,
  xOnly,
  yOnly
}: IProps) => {
  const {
    paddingTop = `regular`,
    paddingBottom = `regular`,
    paddingX = `regular`
  } = config || {};

  return (
    <div
      className={cn(styles.container, className, {
        [styles.paddingTopRegular]: paddingTop === `regular` && !xOnly,
        [styles.paddingTopSmall]: paddingTop === `small` && !xOnly,
        [styles.paddingBottomRegular]: paddingBottom === `regular` && !xOnly,
        [styles.paddingBottomSmall]: paddingBottom === `small` && !xOnly,
        [styles.paddingXRegular]: paddingX === `regular` && !yOnly,
        [styles.paddingXSmall]: paddingX === `small` && !yOnly
      })}
    >
      {children}
    </div>
  );
};

export default SlicePadding;
