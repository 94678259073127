// extracted by mini-css-extract-plugin
export var buttonIcon = "styles-module--buttonIcon--3AKXE";
export var container = "styles-module--container--V35gu";
export var content = "styles-module--content--HIOsi";
export var desktop = "1340px";
export var giant = "2200px";
export var handle = "styles-module--handle--Cml3j";
export var label = "styles-module--label--OjO+h";
export var labelText = "styles-module--labelText--h8L7k";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var profilePic = "styles-module--profilePic--8MEfi";
export var saveButton = "styles-module--saveButton--5Uts2";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var topContent = "styles-module--topContent--hO62A";