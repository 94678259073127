// extracted by mini-css-extract-plugin
export var blockGraphic = "styles-module--blockGraphic--TjYQQ";
export var bottomLeft = "styles-module--bottomLeft--ua6Ln";
export var bottomMiddle = "styles-module--bottomMiddle--v4AzN";
export var bottomRight = "styles-module--bottomRight--lSTET";
export var content = "styles-module--content--KbjrA";
export var desktop = "1340px";
export var giant = "2200px";
export var icon = "styles-module--icon--EjyA7";
export var iconContainer = "styles-module--iconContainer--sJ+46";
export var image = "styles-module--image--rxnlJ";
export var imageContainer = "styles-module--imageContainer--ep2km";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var linkTile = "styles-module--linkTile--OFpur";
export var middleLeft = "styles-module--middleLeft--d2vms";
export var middleRight = "styles-module--middleRight--Pien7";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var subheading = "styles-module--subheading--OoKFu";
export var tablet = "769px";
export var text = "styles-module--text--nDexp";
export var topLeft = "styles-module--topLeft--ny0SE";