import React from "react";
import * as styles from "./styles.module.scss";

const FeaturedText = ({ data: { url } }) => (
  <section className={styles.container}>
    <video playsInline muted loop autoPlay src={url} />
  </section>
);

export default FeaturedText;
