import React, { useState } from "react";
import { Button, SliceConfig, pgSlices, TPGSlice } from "~components";
import * as styles from "./styles.module.scss";
import cn from "classnames";
import { camelCaseToTitleCase } from "~utils";

type IPaddingOption = `regular` | `small` | `none`;

export interface ISlicePadding {
  paddingTop?: IPaddingOption;
  paddingBottom?: IPaddingOption;
  paddingX?: IPaddingOption;
}
export interface ISliceConfig {
  backgroundColor?: {
    hex: string;
  };
  textColor?: {
    hex: string;
  };
  slicePadding?: ISlicePadding;
  disableBottomBorder?: boolean;
}

export interface IPagebuilderSlices {
  sliceConfig: ISliceConfig;
}
interface IProps {
  data: IPagebuilderSlices;
}

const PagebuilderSlices = ({ data: { sliceConfig } }: IProps) => {
  const playgroundSlices = Object.keys(pgSlices) as TPGSlice[];

  const [activeSlice, setActiveSlice] = useState<TPGSlice>(playgroundSlices[0]);

  const PlaygroundElement = pgSlices[activeSlice];

  return (
    <SliceConfig config={sliceConfig}>
      <h1 className={cn("h1", styles.title)}>Slice Playground</h1>

      {playgroundSlices.length > 1 && (
        <div>
          <h2 className={cn(`h3`, styles.selectText)}>Select Slice</h2>

          <div className={styles.selectSliceButtons}>
            {playgroundSlices.map((slice, i) => (
              <Button
                key={i}
                variant={activeSlice === slice ? `primary` : `secondary`}
                onClick={() => setActiveSlice(slice)}
              >
                {camelCaseToTitleCase(slice)}
              </Button>
            ))}
          </div>
        </div>
      )}

      <PlaygroundElement />
    </SliceConfig>
  );
};

export default PagebuilderSlices;
