import { graphql, useStaticQuery } from "gatsby";

export const usePageNavigation = () => {
  const {
    allSanityCategory: { nodes: pageCategories }
  } = useStaticQuery(
    graphql`
      query PageNavigation {
        allSanityCategory(sort: { fields: orderRank, order: ASC }) {
          nodes {
            id
            orderRank
            slug {
              current
            }
            title
            pages {
              title
              slug {
                current
              }
              id
              disabled
            }
          }
        }
      }
    `
  );

  const getPageCategory = (pageSlug) => {
    for (let i = 0; i < pageCategories.length; i++) {
      const category = pageCategories[i];
      for (let j = 0; j < category.pages.length; j++) {
        const page = category.pages[j];
        if (pageSlug?.includes(page.slug.current)) {
          return category;
        }
      }
    }
    return null;
  };

  const getPageIndexInCategory = (currentSlug, currentCategory) => {
    const currentPageIndex = currentCategory?.pages.findIndex((page) =>
      currentSlug.includes(page.slug.current)
    );
    return currentPageIndex;
  };

  const getCategoryIndex = (categoryId) =>
    pageCategories.findIndex((category) => category.id === categoryId);

  // The below functions could be refactored to be more concise, but I wanted to make them as readable as possible.
  // e.g. getNextValidPage could be recursive and just call itself with the next category/page index.

  const getPrevValidCategory = (currentCategory) => {
    const currentCategoryIndex = getCategoryIndex(currentCategory?.id);

    for (let i = currentCategoryIndex - 1; i >= 0; i--) {
      const category = pageCategories[i];

      if (category.pages.some((page) => !page.disabled)) {
        return category;
      }
    }

    return null;
  };

  const getNextValidCategory = (currentCategory) => {
    const currentCategoryIndex = getCategoryIndex(currentCategory?.id);

    for (let i = currentCategoryIndex + 1; i < pageCategories.length; i++) {
      const category = pageCategories[i];

      if (category.pages.some((page) => !page.disabled)) {
        return category;
      }
    }

    return null;
  };

  const getPrevValidPageInCategory = (category, startingIndex) => {
    for (let i = startingIndex; i >= 0; i--) {
      const page = category.pages[i];
      if (!page.disabled) {
        return page;
      }
    }
    return null;
  };

  const getNextValidPageInCategory = (category, startingIndex) => {
    for (let i = startingIndex; i < category?.pages.length; i++) {
      const page = category.pages[i];
      if (!page.disabled) {
        return page;
      }
    }
    return null;
  };

  const getPrevPage = (currentSlug) => {
    const currentPageCategory = getPageCategory(currentSlug);

    const currentPageIndex = getPageIndexInCategory(
      currentSlug,
      currentPageCategory
    );

    const prevValidPageInCategory = getPrevValidPageInCategory(
      currentPageCategory,
      currentPageIndex - 1
    );

    if (prevValidPageInCategory) {
      return prevValidPageInCategory;
    }

    const prevValidCategory = getPrevValidCategory(currentPageCategory);

    if (!prevValidCategory) {
      return null;
    }

    return getPrevValidPageInCategory(
      prevValidCategory,
      prevValidCategory.pages.length - 1
    );
  };

  const getNextPage = (currentSlug) => {
    const currentPageCategory = getPageCategory(currentSlug);

    const currentPageIndex = getPageIndexInCategory(
      currentSlug,
      currentPageCategory
    );

    const nextValidPageInCategory = getNextValidPageInCategory(
      currentPageCategory,
      currentPageIndex + 1
    );

    if (nextValidPageInCategory) {
      return nextValidPageInCategory;
    }

    const nextValidCategory = getNextValidCategory(currentPageCategory);

    if (!nextValidCategory) {
      return null;
    }

    return getNextValidPageInCategory(nextValidCategory, 0);
  };

  return {
    pageCategories,
    getPrevPage,
    getNextPage
  };
};

export default usePageNavigation;
