// extracted by mini-css-extract-plugin
export var backgroundGrid = "styles-module--backgroundGrid--Jozo6";
export var backgroundSegment1 = "styles-module--backgroundSegment1--4zL2A";
export var backgroundSegment10 = "styles-module--backgroundSegment10--AHuEW";
export var backgroundSegment11 = "styles-module--backgroundSegment11---1XwS";
export var backgroundSegment12 = "styles-module--backgroundSegment12--Mf+Cz";
export var backgroundSegment13 = "styles-module--backgroundSegment13--59lug";
export var backgroundSegment14 = "styles-module--backgroundSegment14--ObfA+";
export var backgroundSegment15 = "styles-module--backgroundSegment15--y+f56";
export var backgroundSegment16 = "styles-module--backgroundSegment16--0P2lE";
export var backgroundSegment17 = "styles-module--backgroundSegment17--yIWfe";
export var backgroundSegment18 = "styles-module--backgroundSegment18--M-eNY";
export var backgroundSegment19 = "styles-module--backgroundSegment19--nhzgq";
export var backgroundSegment2 = "styles-module--backgroundSegment2--9wm4e";
export var backgroundSegment20 = "styles-module--backgroundSegment20--yoRMQ";
export var backgroundSegment3 = "styles-module--backgroundSegment3--flaTn";
export var backgroundSegment4 = "styles-module--backgroundSegment4--YmvIw";
export var backgroundSegment5 = "styles-module--backgroundSegment5--6ygsB";
export var backgroundSegment6 = "styles-module--backgroundSegment6--M+-XS";
export var backgroundSegment7 = "styles-module--backgroundSegment7--miJft";
export var backgroundSegment8 = "styles-module--backgroundSegment8--Q7reI";
export var backgroundSegment9 = "styles-module--backgroundSegment9--8Orgr";