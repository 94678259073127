/* eslint-disable react/button-has-type */

import React from "react";
import cn from "classnames";
import { Link } from "gatsby";
import { SVG } from "~components";
import * as styles from "./styles.module.scss";

const Button = ({
  children,
  variant = `primary`,
  buttonType = `button`,
  to,
  href,
  iconLeft,
  iconRight,
  color,
  lightTheme,
  disabled,
  fluid,
  loading,
  onClick,
  className
}) => {
  /** ============================================================================
   * @component
   * Generic global button component
   *
   * @typedef {('primary' | 'secondary' | 'tertiary' | 'text' | 'caption')} Variant
   * @typedef {('button' | 'reset' | 'submit')} ButtonType
   *
   * @param 	{node}  			children			 Button text content
   * @param 	{Variant}   	variant			 	 Style variant
   * @param 	{ButtonType}  buttonType		 Button 'type' attribute
   * @param 	{string}  		to 			 			 Gatsby link path, renders as anchor, only for internal links
   * @param 	{string}  		href 			 		 For external links
   * @param 	{string}  		iconLeft 			 Icon to render left of text
   * @param 	{string}  		iconRight   	 Icon to render right of text
   * @param 	{string}  	  color  				 Hex code to override color styles
   * @param 	{string}  	  className  		 For additional styling config
   * @param 	{boolean} 	  lightTheme     Renders with light theme
   * @param 	{boolean}   	disabled  		 Whether is disabled
   * @param 	{boolean} 	  fluid  		 		 Takes full width of container
   * @param 	{boolean} 	  loading  		 	 Show loading spinner
   * @param 	{function} 	  onClick  		 	 Click handler
   * @return	{node}
   */

  let BtnElement;
  if (to) BtnElement = Link;
  else if (href) BtnElement = `a`;
  else BtnElement = `button`;

  return (
    <BtnElement
      type={to || href ? `` : buttonType}
      href={href}
      target="_blank"
      rel="noreferrer noopener"
      disabled={disabled}
      onClick={() => onClick && onClick()}
      tabIndex={disabled ? `-1` : 0}
      to={to}
      style={{ backgroundColor: color }}
      className={cn(styles.button, className, styles[variant], {
        [styles.light]: lightTheme,
        [styles.fluid]: fluid,
        [styles.loading]: loading,
        [styles.disabled]: disabled
      })}
    >
      <div className={styles.content}>
        {iconLeft && (
          <SVG svg={iconLeft} className={cn(styles.icon, styles.left)} />
        )}
        <span className={variant === `caption` ? `caption` : `button-text`}>
          {children}
        </span>
        {iconRight && (
          <SVG svg={iconRight} className={cn(styles.icon, styles.right)} />
        )}
      </div>
      <div className={styles.loaderContainer}>
        <div className={styles.loader} />
      </div>
    </BtnElement>
  );
};

export default Button;
