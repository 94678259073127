import React from "react";
import * as styles from "./styles.module.scss";

const Background = () => {
  return (
    <div className={styles.backgroundGrid}>
      <div className={styles.backgroundSegment1} />
      <div className={styles.backgroundSegment2} />
      <div className={styles.backgroundSegment3} />
      <div className={styles.backgroundSegment4} />
      <div className={styles.backgroundSegment5} />
      <div className={styles.backgroundSegment6} />
      <div className={styles.backgroundSegment7} />
      <div className={styles.backgroundSegment8} />
      <div className={styles.backgroundSegment9} />
      <div className={styles.backgroundSegment10} />
      <div className={styles.backgroundSegment11} />
      <div className={styles.backgroundSegment12} />
      <div className={styles.backgroundSegment13} />
      <div className={styles.backgroundSegment14} />
      <div className={styles.backgroundSegment15} />
      <div className={styles.backgroundSegment16} />
      <div className={styles.backgroundSegment17} />
      <div className={styles.backgroundSegment18} />
      <div className={styles.backgroundSegment19} />
      <div className={styles.backgroundSegment20} />
    </div>
  );
};

export default Background;
