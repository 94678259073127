// extracted by mini-css-extract-plugin
export var active = "styles-module--active--5M8l7";
export var container = "styles-module--container--WX9Iy";
export var dependencies = "styles-module--dependencies--Nm3o2";
export var description = "styles-module--description--4ZKqe";
export var featureButton = "styles-module--featureButton--exi7I";
export var featureDetails = "styles-module--featureDetails--SGoT1";
export var features = "styles-module--features--5K5Dm";
export var listItem = "styles-module--listItem--5Tl18";
export var price = "styles-module--price--6VJWZ";
export var selectFeatureText = "styles-module--selectFeatureText--YlWtJ";
export var title = "styles-module--title--4CXiY";