// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--Oour6";
export var appearDown = "styles-module--appear-down--5AeYV";
export var appearLeft = "styles-module--appear-left--tQKKT";
export var appearRight = "styles-module--appear-right--4ECzb";
export var appearUp = "styles-module--appear-up--8T+T9";
export var container = "styles-module--container--2hFEX";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var nextButton = "styles-module--nextButton--lVk3X";
export var prevButton = "styles-module--prevButton--OuriQ";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";