import React, { useState, useEffect } from "react";
import pretty from "pretty"; // Import pretty

const useReactHtml = (domRef: React.RefObject<HTMLDivElement>) => {
  const [html, setHtml] = useState("");

  useEffect(() => {
    if (domRef.current) {
      const formattedHtml = pretty(domRef.current.innerHTML);
      setHtml(formattedHtml);
    }
  });

  return html;
};

export default useReactHtml;
