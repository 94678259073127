import React from "react";
import {
  SliceConfig,
  Grid,
  PortableTextRenderer,
  IntersectionAnimation,
  Button,
  LinkTag
} from "~components";
import * as styles from "./styles.module.scss";

const HeadlineTextRow = ({
  data: { headline, _rawText, links, tags, sliceConfig }
}) => (
  <section className={styles.container}>
    <SliceConfig config={sliceConfig}>
      <Grid>
        <div className={styles.leftColumn}>
          <h3 className="h3">
            <IntersectionAnimation>{headline}</IntersectionAnimation>
            {tags?.[0] && (
              <div className={styles.linkTags}>
                {tags.map((tag, i) => (
                  <LinkTag
                    key={i}
                    linkType={tag.linkType}
                    hoverColor={tag.hoverColor}
                    linkExternal={tag.linkExternal}
                    linkInternal={tag.linkInternal}
                  />
                ))}
              </div>
            )}
          </h3>
        </div>

        <div className={styles.rightColumn}>
          <IntersectionAnimation delay={150}>
            <PortableTextRenderer rawText={_rawText} />
          </IntersectionAnimation>

          {links?.[0] && (
            <IntersectionAnimation className={styles.buttons} delay={150}>
              <div>
                {links?.map((link, i) => {
                  if (link._type === `linkInternal`) {
                    return (
                      <Button key={i} to={`/${link.reference.slug.current}`}>
                        {link.title}
                      </Button>
                    );
                  }

                  if (link._type === `linkExternal`) {
                    return (
                      <Button key={i} href={link.url}>
                        {link.title}
                      </Button>
                    );
                  }

                  return null;
                })}
              </div>
            </IntersectionAnimation>
          )}
        </div>
      </Grid>
    </SliceConfig>
  </section>
);

export default HeadlineTextRow;
