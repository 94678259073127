import React from "react";
import cn from "classnames";
import { ColorPicker } from "~components";
import { camelCaseToTitleCase } from "~utils";
import * as styles from "./styles.module.scss";

interface IProps {
  config: any;
  updateConfig: (field: string, value: any) => void;
  field: string;
  options?: string[];
}

const SchemaColorPicker = ({
  config,
  updateConfig,
  field,
  options
}: IProps) => {
  const activeColor = config[field];
  return (
    <>
      <div className={cn(`caption`, styles.schemaLabel)}>
        {camelCaseToTitleCase(field)}
      </div>

      <ColorPicker
        colors={options || []}
        selectedColor={activeColor}
        updateSelectedColor={(color) => updateConfig(field, color)}
      />
    </>
  );
};

export default SchemaColorPicker;
