// extracted by mini-css-extract-plugin
export var buttonContent = "styles-module--buttonContent--wlyD0";
export var buttonContentContainer = "styles-module--buttonContentContainer--8eLkH";
export var container = "styles-module--container--O1W6d";
export var desktop = "1340px";
export var giant = "2200px";
export var icon = "styles-module--icon--MdpT6";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var optionButton = "styles-module--optionButton--hRHkj";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var title = "styles-module--title--1t70H";