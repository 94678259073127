import React from "react";
import * as styles from "./styles.module.scss";
import { IComponentSchemaField } from "~hooks";
import Configuration from "./components/Configuration";
import Canvas from "./components/Canvas";
import CSSInspector from "./components/CSSInspector";
import HTMLInspector from "./components/HTMLInspector";
import JSInspector from "./components/JSInspector";

export type TInspectorDirectory = `components` | `slices`;

interface IProps {
  componentSchema: IComponentSchemaField[];
  config: any;
  updateConfig: (field: string, value: any) => void;
  previewComponent: JSX.Element;
  directory: TInspectorDirectory;
}

const PlaygroundWrapper = ({
  componentSchema,
  previewComponent,
  config,
  updateConfig,
  directory
}: IProps) => {
  return (
    <>
      <div className={styles.configAndPreview}>
        <Configuration
          componentSchema={componentSchema}
          config={config}
          updateConfig={updateConfig}
        />
        <Canvas previewComponent={previewComponent} />
      </div>

      <div className={styles.inspectors}>
        <CSSInspector
          directory={directory}
          componentName={previewComponent.props.componentName}
        />
        <HTMLInspector previewComponent={previewComponent} />
        {/* <JSInspector
          directory={directory}
          componentName={previewComponent.props.componentName}
        /> */}
      </div>
    </>
  );
};

export default PlaygroundWrapper;
