import React from "react";
import { Image, SliceConfig, IntersectionAnimation } from "~components";
import * as styles from "./styles.module.scss";

const SimpleImage = ({ data: { image, sliceConfig } }) => {
  if (!image) {
    return null;
  }

  return (
    <section>
      <SliceConfig config={sliceConfig}>
        <IntersectionAnimation animation="fadeGrow">
          <Image className={styles.image} image={image} />
        </IntersectionAnimation>
      </SliceConfig>
    </section>
  );
};

export default SimpleImage;
