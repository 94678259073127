// extracted by mini-css-extract-plugin
export var actionButtons = "styles-module--actionButtons--UEKVP";
export var buttonIcon = "styles-module--buttonIcon--K1cFo";
export var container = "styles-module--container--lYyti";
export var content = "styles-module--content--nsJOC";
export var desktop = "1340px";
export var editIcon = "styles-module--editIcon--FZZBz";
export var editMediaButton = "styles-module--editMediaButton--ZVj6c";
export var giant = "2200px";
export var handle = "styles-module--handle--PIyLM";
export var imagePlaceholder = "styles-module--imagePlaceholder--K94I9";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var media = "styles-module--media--LIEOa";
export var mediaContainer = "styles-module--mediaContainer--vPOkX";
export var mobile = "400px";
export var profilePic = "styles-module--profilePic--pDLMs";
export var saveButton = "styles-module--saveButton--P078U";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var textarea = "styles-module--textarea--Ygbtc";
export var topContent = "styles-module--topContent--YovfU";