// extracted by mini-css-extract-plugin
export var colorBackground = "styles-module--colorBackground--fGwZT";
export var container = "styles-module--container--fP+B3";
export var content = "styles-module--content--4v4yX";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var principle = "styles-module--principle--SRW0M";
export var principles = "styles-module--principles--uBcJv";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var title = "styles-module--title--VSdW-";
export var visible = "styles-module--visible--u-ZQx";