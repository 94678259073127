import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";
import { SVG } from "~components";

interface IProps {
  title: string;
}

const DisabledFeatureLink = ({ title }: IProps) => {
  return (
    <a href="mailto:adnaan@loveandmoney.agency" className={styles.container}>
      <div className={styles.iconContainer}>
        <SVG svg="lock" className={cn(styles.icon, styles.lock)} />
        <SVG svg="unlock" className={cn(styles.icon, styles.unlock)} />
      </div>

      <div className={styles.disabledLink}>{title}</div>

      <div className={styles.hoverLabelContainer}>
        <div className={cn("b2", styles.hoverLabel)}>Get In Touch</div>
      </div>
    </a>
  );
};

export default DisabledFeatureLink;
