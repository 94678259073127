// Not updated to V2
// Removed from pagebuilder

import React from "react";
import { Image, Link } from "~components";
import * as styles from "./styles.module.scss";

/** ============================================================================
 * Slice
 */
const LinkGrid = ({ id, className, data: { linkGridItems } }) => (
  <section sliceid={id} className={[className, styles.container].join(` `)}>
    <ul className={styles.grid}>
      {linkGridItems.map(
        ({
          backgroundColor: background,
          fontColor: color,
          disabled,
          link,
          heading,
          subheading,
          backgroundImage
        }) => {
          const itemClassName = [
            styles.gridItem,
            // styles[`gridItem${size}`],
            styles.gridItemM,
            disabled ? styles.gridItemDisabled : ``
          ].join(` `);

          return (
            <li
              key={`link-grid-${heading}`}
              style={{
                background: background?.hex || `white`,
                color: color?.hex || `black`
              }}
              className={itemClassName}
            >
              {backgroundImage && (
                <figure className={[styles.gridItemBackground]}>
                  <Image image={backgroundImage} />
                </figure>
              )}

              <Link to={link.url}>
                <div className={styles.gridItemContent}>
                  <header>
                    {subheading && <h3 className="b2">{subheading}</h3>}

                    <h2 className="b1">{heading}</h2>
                  </header>

                  <p className="button-text">{link.text}</p>
                </div>
              </Link>
            </li>
          );
        }
      )}
    </ul>
  </section>
);

export default LinkGrid;
