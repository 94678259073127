import React from "react";
import cn from "classnames";
import { camelCaseToTitleCase } from "~utils";
import * as styles from "./styles.module.scss";
import { Button } from "~components";

interface IProps {
  config: any;
  updateConfig: (field: string, value: any) => void;
  field: string;
  options: string[];
}

const SchemaOptions = ({ config, updateConfig, field, options }: IProps) => (
  <>
    <div className={cn(`caption`, styles.schemaLabel)}>
      {camelCaseToTitleCase(field)}
    </div>

    <div className={styles.buttons}>
      {options.map((option) => (
        <Button
          key={option}
          variant={config[field] === option ? `primary` : `secondary`}
          onClick={() => updateConfig(field, option)}
        >
          {camelCaseToTitleCase(option)}
        </Button>
      ))}
    </div>
  </>
);

export default SchemaOptions;
