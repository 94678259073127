// extracted by mini-css-extract-plugin
export var button = "styles-module--button--bBpTZ";
export var buttonIcon = "styles-module--buttonIcon--Tt2DZ";
export var container = "styles-module--container--d0iR4";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var padding = "styles-module--padding--q-kbs";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var sticky = "styles-module--sticky--btRZV";
export var tablet = "769px";
export var titleContainer = "styles-module--titleContainer--Tu2dv";