// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--3SMkN";
export var appearDown = "styles-module--appear-down--cmmko";
export var appearLeft = "styles-module--appear-left--uUeEH";
export var appearRight = "styles-module--appear-right--o984A";
export var appearUp = "styles-module--appear-up--vWYeW";
export var bgOverlay = "styles-module--bgOverlay--yMBIA";
export var categoryGroup = "styles-module--categoryGroup--Tj0wX";
export var desktop = "1340px";
export var giant = "2200px";
export var hamburger = "styles-module--hamburger--JGgTN";
export var header = "styles-module--header--rLr89";
export var homeLink = "styles-module--homeLink--dFBov";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var line = "styles-module--line--ex44J";
export var logoImage = "styles-module--logoImage--PXFyY";
export var mobile = "400px";
export var nav = "styles-module--nav--AuvVn";
export var navContainer = "styles-module--navContainer--60Ulq";
export var navItems = "styles-module--navItems--LnFHR";
export var open = "styles-module--open--XL5EC";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var solidBackground = "styles-module--solidBackground--6-o8s";
export var tablet = "769px";
export var visible = "styles-module--visible--8+mGU";
export var wordmark = "styles-module--wordmark--0j7kz";