import React from "react";
import * as styles from "./styles.module.scss";
import { useApp } from "~hooks";
import { Button } from "~components";
import cn from "classnames";
import domToImage from "dom-to-image-more";

export interface IProps {
  activePostContent: string;
  getPostContent?: () => Promise<void>;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
  mediaContainerDomNode?: HTMLDivElement | null | undefined;
}

const ActionButtons = ({
  activePostContent,
  setIsEditing,
  getPostContent,
  className,
  mediaContainerDomNode
}: IProps) => {
  const { updateNotificationMessage } = useApp();

  const copyResults = (text: string) => {
    navigator.clipboard.writeText(text);
    updateNotificationMessage(`Copied!`);
  };

  const downloadDomNodeAsImage = () => {
    if (!mediaContainerDomNode) return;

    const mediaTextContainerDomNode = mediaContainerDomNode.querySelector(
      "#media-text-container"
    ) as HTMLDivElement;
    if (!mediaTextContainerDomNode) return;

    domToImage
      .toPng(mediaTextContainerDomNode)
      .then(function (dataUrl: any) {
        var img = new Image();
        img.src = dataUrl;
        const link = document.createElement("a");
        link.href = img.src;
        link.download = "eup-social-image.png";
        link.click();
      })
      .catch(function (error: any) {
        console.error("oops, something went wrong!", error);
      });
  };

  if (!activePostContent) return null;

  return (
    <div className={cn(styles.messageActionButtons, className)}>
      <Button
        onClick={() => copyResults(activePostContent)}
        variant="tertiary"
        iconLeft="copy"
      >
        Copy
      </Button>

      {setIsEditing && (
        <Button
          onClick={() => setIsEditing(true)}
          variant="tertiary"
          iconLeft="edit"
        >
          Edit
        </Button>
      )}

      {getPostContent && (
        <Button
          onClick={() => getPostContent()}
          variant="tertiary"
          iconLeft="refresh"
        >
          Regenerate
        </Button>
      )}

      {mediaContainerDomNode && (
        <Button
          onClick={downloadDomNodeAsImage}
          variant="tertiary"
          iconLeft="download"
        >
          Download
        </Button>
      )}
    </div>
  );
};

export default ActionButtons;
