// extracted by mini-css-extract-plugin
export var backButton = "styles-module--backButton--gpRit";
export var button = "styles-module--button--xIo5B";
export var buttonIcon = "styles-module--buttonIcon--H-0pE";
export var container = "styles-module--container--VZhGk";
export var contentText = "styles-module--contentText--Z+h7P";
export var desktop = "1340px";
export var editButton = "styles-module--editButton--RcwpL";
export var editButtonContainer = "styles-module--editButtonContainer--yaND+";
export var editIcon = "styles-module--editIcon--KT60N";
export var editInput = "styles-module--editInput--p68NP";
export var editInputContainer = "styles-module--editInputContainer--xM0xO";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var messageActionButtons = "styles-module--messageActionButtons--jGpku";
export var mobile = "400px";
export var outputButtons = "styles-module--outputButtons--9wV8g";
export var platformButtons = "styles-module--platformButtons--Ze7Al";
export var postPreview = "styles-module--postPreview--QHd3O";
export var promptContainer = "styles-module--promptContainer--XwtLa";
export var saveButton = "styles-module--saveButton--weJQD";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var textInput = "styles-module--textInput--xBQ3M";
export var title = "styles-module--title--kH2n3";