import React, { useEffect, useState } from "react";
import * as styles from "./styles.module.scss";
import PlaygroundSection from "../PlaygroundSection";
import { useApp } from "~hooks";
import type { TInspectorDirectory } from "~components";

interface IProps {
  componentName: string;
  directory: TInspectorDirectory;
}

const CSSInspector = ({ componentName, directory }: IProps) => {
  const [textContent, setTextContent] = useState(``);

  useEffect(() => {
    const fetchCssTextContent = async () => {
      try {
        const response = await fetch(`/${directory}/${componentName}.css`);

        if (response.status === 404) {
          throw new Error(`File not found`);
        }

        const text = await response.text();

        console.log(text);
        setTextContent(text);
      } catch (error: unknown) {
        if (error instanceof Error) {
          setTextContent(error.message);
        } else {
          console.error(error);
          setTextContent(`An unknown error occured`);
        }
      }
    };

    setTextContent(``);
    fetchCssTextContent();
  }, [componentName]);

  const { updateNotification } = useApp();

  const copyCSSToClipboard = () => {
    navigator.clipboard.writeText(textContent);
    updateNotification(`Copied to clipboard!`);
  };

  return (
    <PlaygroundSection
      title="CSS"
      button={{
        icon: "copy",
        labelText: `Copy CSS to clipboard`,
        onClick: copyCSSToClipboard
      }}
    >
      <pre className={styles.code}>{textContent}</pre>
    </PlaygroundSection>
  );
};

export default CSSInspector;
