export interface IFeature {
  name: string;
  slug: string;
  price: string;
  description: string;
  dependentFeatureSlugs: string[];
}

const features: IFeature[] = [
  {
    name: "Links & Resources",
    dependentFeatureSlugs: [],
    description:
      "House all brand assets right in your Toolkit. Easily share with your team and partners.",
    price: "$1000.00",
    slug: "links-and-resources"
  },
  {
    name: "Motion Principles",
    dependentFeatureSlugs: ["links-and-resources"],
    description:
      "See how motion is used in the brand and how to use it in your work.",
    price: "$2000.00",
    slug: "motion-principles"
  },
  {
    name: "Web Components",
    dependentFeatureSlugs: [],
    description:
      "Inspect, preview and configure the components used on your websites.",
    price: "$1500.00",
    slug: "web-components"
  },
  // duplicating
  {
    name: "Links & Resourcez",
    dependentFeatureSlugs: [],
    description:
      "House all brand assets right in your Toolkit. Easily share with your team and partners.",
    price: "$1000.00",
    slug: "links-and-resourcez"
  },
  {
    name: "Motion Principlez",
    dependentFeatureSlugs: ["links-and-resources", "web-componentz"],
    description:
      "See how motion is used in the brand and how to use it in your work.",
    price: "$2000.00",
    slug: "motion-principlez"
  },
  {
    name: "Web Componentz",
    dependentFeatureSlugs: [],
    description:
      "Inspect, preview and configure the components used on your websites.",
    price: "$1500.00",
    slug: "web-componentz"
  }
];

export default features;
