import React from "react";
import cn from "classnames";
import profilePic from "./assets/eup-icon.png";
import * as styles from "./styles.module.scss";
import ActionButtons from "../ActionButtons";
import useMagicPenContext from "../../context";
import EditingUI from "../EditingUI";
import LoadingOverlay from "../LoadingOverlay";
import AddMediaButton from "../AddMediaButton";
import ImageUI from "../ImageUI";

interface IProps {
  content?: string;
  getPostContent?: () => Promise<void>;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  isEditing: boolean;
  handleUpdateActivePostContent: (updatedContent: string) => void;
}

const Instagram = ({
  content,
  getPostContent,
  setIsEditing,
  isEditing,
  handleUpdateActivePostContent
}: IProps) => {
  const { setShowMediaLibrary, selectedMedia } = useMagicPenContext();

  const isLoading = !content && !isEditing;

  const userHandle = "euphemia_invest";

  return (
    <div className={styles.container}>
      <LoadingOverlay isLoading={isLoading} />

      <div className={styles.topContent}>
        <img src={profilePic} className={styles.profilePic} />
        <div className={cn("b1", styles.handle)}>{userHandle}</div>
      </div>

      {!selectedMedia && (
        <AddMediaButton setShowMediaLibrary={setShowMediaLibrary} />
      )}

      {selectedMedia && (
        <ImageUI
          selectedMedia={selectedMedia}
          setShowMediaLibrary={setShowMediaLibrary}
        />
      )}

      {!isEditing && (
        <p className={cn("b1", styles.content)}>
          <span className={styles.handle}>{userHandle}</span>
          <span> · {content}</span>
        </p>
      )}

      {isEditing && (
        <EditingUI
          content={content}
          handleUpdateActivePostContent={handleUpdateActivePostContent}
          setIsEditing={setIsEditing}
        />
      )}

      {!isEditing && (
        <ActionButtons
          activePostContent={content || ""}
          getPostContent={getPostContent}
          setIsEditing={setIsEditing}
        />
      )}
    </div>
  );
};

export default Instagram;
